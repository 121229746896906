import React, { useState } from 'react';

import LandingNavBar from 'components/navigation/nav-bar/landing-nav-bar';
import NoAuthSideBar from 'components/navigation/side-bar/no-auth-side-bar';

const LandingPageNavigation = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
        <>
          <LandingNavBar
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            />

              <NoAuthSideBar
                menuOpen={menuOpen}
                setMenuOpen={setMenuOpen}/>

        </>
  );
};
export default LandingPageNavigation;
