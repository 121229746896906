import React, { useState } from 'react';
import { func, bool } from 'prop-types';
import loadable from '@loadable/component';
import { navigate, Link } from 'gatsby';
import classNames from 'classnames';

import {
  HOME_LOGGED_IN_ROUTE,
  LOGIN_ROUTE,
  LANDING_PAGE,
  REGISTRATION_START,
} from 'constants/navigation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-regular-svg-icons';

import {
  faComputerClassic,
  faSignIn,
  faSmilePlus,
} from '@fortawesome/pro-light-svg-icons';

import { isAuthenticated } from 'services/authentication-service';

import useStyles, { useAppBarClasses } from './styles';
import svg from './tl_dark.svg';

const AppBar = loadable(() => import('@material-ui/core/AppBar'));
const Toolbar = loadable(() => import('@material-ui/core/Toolbar'));
const Hidden = loadable(() => import('@material-ui/core/Hidden'));
const Typography = loadable(() => import('@material-ui/core/Typography'));
const Button = loadable(() => import('@material-ui/core/Button'));
const IconButton = loadable(() => import('@material-ui/core/IconButton'));

const LandingNavBar = ({
  menuOpen,
  setMenuOpen,
}) => {
  const styles = useStyles();
  const appBarClasses = useAppBarClasses();
  const [disabled, setDisabled] = useState(false);

  const authenticated = isAuthenticated();

  const homeRoute = LANDING_PAGE;

  const getActionButton = () => {
    if (authenticated) {
      return (
         <Button
            aria-label="Go to App"
            onClick={() => {
              setDisabled(true);
              navigate(HOME_LOGGED_IN_ROUTE);
            }}
            className={styles.landingBarButton}
            size="large"
            variant="outlined"
            disabled={disabled}
           >
           <FontAwesomeIcon
             icon={faComputerClassic}
             size="2x"
             className={styles.navIcon}
             />
             <Hidden xsDown>Launch App</Hidden>
           </Button>
      );
    }
    return (
       <div className={styles.landingBarButton}>
         <span className={styles.firstButton}>
           <Button
            aria-label="Login"
            onClick={() => {
              setDisabled(true);
              navigate(LOGIN_ROUTE);
            }}
            size="large"
            variant="outlined"
            disabled={disabled}
           >
           <FontAwesomeIcon
             icon={faSignIn}
             size="2x"
             className={styles.navIcon}
             />
             <Hidden xsDown>Login</Hidden>
           </Button>
         </span>
         <span>
           <Button
            aria-label="Register"
            onClick={() => {
              setDisabled(true);
              navigate(REGISTRATION_START);
            }}
            size="large"
            variant="outlined"
            disabled={disabled}
           >
           <FontAwesomeIcon
             icon={faSmilePlus}
             size="2x"
             className={styles.navIcon}
             />
             <Hidden xsDown>Register</Hidden>
           </Button>
         </span>
       </div>
    );
  };

  return (
      <AppBar
        elevation={1}
        position="fixed"
        classes={appBarClasses}
        className={classNames(
          styles.appBar, (menuOpen) && styles.appBarShift,
        )}
        >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open menu"
            onClick={() => setMenuOpen(!menuOpen)}>
            <FontAwesomeIcon icon={faBars} />
          </IconButton>
          <Hidden smUp>
            <Link className={styles.logoText} to={homeRoute}>
              <img
                className={styles.logo}
                src={svg}
                alt="Total Loyalty"
                height="25"
                width="25"
              />
            </Link>
          </Hidden>
          <Hidden xsDown>
            <Link className={styles.logoText} to={homeRoute}>
              <img
                className={styles.logo}
                src={svg}
                alt="Total Loyalty"
                height="25"
                width="25"
              />
              </Link>
          <Typography variant="subtitle1" component="h6">
            <Link className={styles.logoText} to={homeRoute}>
            TOTAL LOYALTY
            </Link>
          </Typography>
          </Hidden>
          {getActionButton()}
        </Toolbar>
      </AppBar>
  );
};

LandingNavBar.propTypes = {
  menuOpen: bool.isRequired,
  setMenuOpen: func.isRequired,
};

export default LandingNavBar;
